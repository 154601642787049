<template>
  <div>
    <div class="row">
      <div v-if="with_filter" class="col-md-3">
        <base-input placeholder="Поиск" class="mt-3" v-model="search_filter" @input="reloadAction(page, search_filter, base_price_filter)">
          </base-input>
      </div>
      <div v-if="with_num_filter" class="mt-2">
        <label class="control-label">Исключить без себестоимости</label>
        <base-checkbox v-model="base_price_filter" @input="reloadAction(page, search_filter, base_price_filter)">
        </base-checkbox>
      </div>
    </div>
    
    <table class="table tablesorter" :class="tableClass">
      <thead :class="theadClasses">
        <tr>
          <slot v-if="data.length !== 0" name="columns">
            <th v-for="column in columns" :key="column" :class="{ 'd-none d-md-table-cell' : mobileHideColumns.includes(column)}">{{ column ? $t('column.' + column.toLowerCase()) : '' }}</th>
            <th v-for="(column, index) in additional_columns" :key="column" :class="{ 'd-none d-md-table-cell' : mobileHideColumns.includes(column)}" v-html="additional_columns_name[index]"></th>
          </slot>
        </tr>
      </thead>
      <tbody :class="tbodyClasses">
        <tr v-for="(item, index) in data" :key="index">
          <slot :row="item">
            <td v-for="(column, index) in columns" :key="index" v-if="hasValue(item, column)" :class="{ 'd-none d-md-table-cell' : mobileHideColumns.includes(column)}">
              <div :class="{'color_red': color_columns.hasOwnProperty(column) && item[color_columns[column]]}" v-html="itemValue(item, column)">
              </div>
            </td>

            <td v-for="(column, index) in additional_columns" :key="index" v-if="hasValue(item, column)" :class="{ 'd-none d-md-table-cell' : mobileHideColumns.includes(column)}">
              <div :class="{'color_red': color_columns.hasOwnProperty(column) && item[color_columns[column]]}" v-html="itemValue(item, column)">
              </div>
              <base-button v-if="additional_columns_with_action.includes(column)" class="btn-action" slot="footer" type="secondary" fill @click="additional_column_edit_action(item)"><i
                  class="tim-icons icon-pencil"></i></base-button>
            </td>
            <td v-if="actions.includes('edit')" class="table-action">
              <base-button class="btn-action" slot="footer" type="secondary" fill @click="editAction(item[idField])"><i
                  class="tim-icons icon-pencil"></i></base-button>
            </td>
            <td v-if="actions.includes('delete')" class="table-action">
              <base-button class="btn-action" slot="footer" type="danger" fill @click="deleteAction(item[idField])"><i
                  class="tim-icons icon-trash-simple"></i></base-button>
            </td>
          </slot>
        </tr>
      </tbody>
    </table>
    <nav v-if="data.length !== 0 && use_paging">
      <ul class="pagination">
        <li class="page-item" v-if="page > 1" @click="pageAction(false)">
          <a class="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">Previous</span>
          </a>
        </li>
        <li class="page-item" v-if="page > 1" @click="pageAction(false)"><a class="page-link" href="#">{{ page - 1 }}</a></li>
        <li class="page-item active"><a class="page-link" href="#">{{ page }}</a></li>
        <li class="page-item" v-if="has_next" @click="pageAction(true)"><a class="page-link" href="#">{{ page + 1 }}</a></li>
        <li class="page-item" v-if="has_next" @click="pageAction(true)">
          <a class="page-link" href="#" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
import Modal from '@/components/Modal';

export default {
  name: 'base-table',
  components: {
    Modal
  },
  created() {
  },
  data() {
    return {
      qrModalVisible: false,
      qrData: '',
      search_filter: "",
      base_price_filter: false
    }
  },
  props: {
    with_num_filter: {
      type: Boolean,
      default: false
    },
    color_columns: {
      type: Object,
      default: {}
    },
    with_filter: {
      type: Boolean,
      default: false
    },
    page: {
      type: Number,
      default: 1
    },
    has_next: {
      type: Boolean,
      default: false
    },
    use_paging: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      default: () => [],
      description: "Table columns"
    },
    additional_columns: {
      type: Array,
      default: () => [],
      description: "Table columns"
    },
    additional_columns_name: {
      type: Array,
      default: () => [],
      description: "Table columns"
    },
    additional_columns_with_action: {
      type: Array,
      default: () => [],
      description: "Table columns"
    },
    additional_column_edit_action: {
      default: () => [],
    },
    mobileHideColumns: {
      type: Array,
      default: () => [],
      description: "Table columns"
    },
    data: {
      type: Array,
      default: () => [],
      description: "Table data"
    },
    type: {
      type: String, // striped | hover
      default: "",
      description: "Whether table is striped or hover type"
    },
    theadClasses: {
      type: String,
      default: '',
      description: "<thead> css classes"
    },
    tbodyClasses: {
      type: String,
      default: '',
      description: "<tbody> css classes"
    },
    idField: {
      type: String,
      default: 'id',
      description: "Id field for items"
    },
    actions: {
      type: Array,
      default: () => [],
      description: "Table actions"
    },
    editAction: {
      default: () => [],
    },
    editActionRoles: {
      default: () => [],
    },
    deleteAction: {
      default: () => [],
    },
    reloadAction: {
      default: () => [],
    }
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      if (typeof item[column.toLowerCase()] == "boolean") {
          if (item[column.toLowerCase()]) {
            return "<i class=\"tim-icons icon-check-2\"></i>"
          } else {
            return "<i class=\"tim-icons icon-simple-remove\"></i>"
          }
      }
      return item[column.toLowerCase()];
    },
    qrAction(id) {
      this.qrModalVisible = true
      this.qrData = id
    },
    pageAction(next) {
      if (next) {
        this.page += 1
      } else {
        this.page -= 1
      }
      this.reloadAction(this.page, this.search_filter, this.base_price_filter);
    }
  }
};
</script>
<style>
.color_red {
  color: red;
}

.table-action {
  width: 1%;
  white-space: nowrap;
}

.modal-qr .modal-content {
  width: fit-content;
}

.modal-qr {
  background: beige !important;
}
</style>
